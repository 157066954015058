

const Layout = (props) => {
    return (
        <section className=''>
            {props.children}
        </section>
    );
}
export default Layout

