// GoogleAnalytics.js
import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize('AW-946658077');
};

export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
};

export const eventLog = (payload) => {
    ReactGA.event(payload);
}

 

