import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
const Protected = (Props) => {
    const navigate = useNavigate()

    const checkProcess = async () => {
        const process = await cookies.get('process');
        if (!process) {
            navigate('/not-found')
        };
        
    }
    

    useEffect(() => { checkProcess() },[])
    const { Component } = Props;

    return (
        <>
            <Component />
        </>
    )
}
export default Protected