

const Header = () => {

    return (
        <>
            <section className="top-bar">
                <div className="container">
                    <div className="topbar-content">
                        <div className="top-logo">
                            <a href='/'>
                                <img
                                    src="images/logo.png"
                                    className="img-fluid"
                                    alt="Shipsagar Logo"
                                />
                            </a>
                        </div>
                        <div className="top-link">
                            <a href="tel:+91-9311746788">
                                <i className="fa-solid fa-phone" />
                                <span>+91-9311746788</span>
                            </a>
                            <a href="tel:+91-9311133772">
                                <i className="fa-solid fa-phone" />
                                <span>+91-9311133772</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Header