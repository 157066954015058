import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-top">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="copyright">
                                    <p>Copyright © 2023 ShipSagar All rights reserved.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 text-center">
                                <div className="ftr-social">
                                    <a href="https://www.facebook.com/shipsagarofficial" target="_blank"><i className="fa-brands fa-facebook-f" /></a>
                                    <a href="https://twitter.com/shipsagar" target="_blank"><i className="fa-brands fa-x-twitter" /></a>
                                    <a href="https://www.instagram.com/shipsagarofficial/" target="_blank"><i className="fa-brands fa-instagram" /></a>
                                    <a href="https://www.linkedin.com/company/shipsagar" target="_blank"><i className="fa-brands fa-linkedin-in" /></a>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="powered-by">
                                    <p>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 text-md-end">
                                <div className="powered-by">
                                    <p>Powered By: <a href="https://www.sagarinfotech.com/" target="_blank" rel="no-follow"> Sagar Informatics Pvt.
                                        Ltd.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="col-12">
                            <div className="tracking-url-wrapper">
                                <a href="#">Aramex</a>
                                <a href="#">Blue Dart</a>
                                <a href="#">Delhivery</a>
                                <a href="#">DHL</a>
                                <a href="#">DTDC</a>
                                <a href="#">FedEx</a>
                                <a href="#">TNT</a>
                                <a href="#">Atlantic</a>
                                <a href="#">Gati</a>
                                <a href="#">Ecom Express</a>
                                <a href="#">DotZot</a>
                                <a href="#">First Flight</a>
                                <a href="#">Xpress Bees</a>
                                <a href="#">India Post</a>
                                <a href="#">UPS</a>
                                <a href="#">Trackon</a>
                                <a href="#">Bombino Express</a>
                                <a href="#">Wow Express</a>
                                <a href="#">500+ Carriers</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer