import { useEffect } from "react"
import { Link } from 'react-router-dom'

const Pricing = () => {

    useEffect(() => {
        document.title = 'Pricing | ShipSagar'
    }, [])
    document.title = ''
    return (
        <>
            <section className="pricing-wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4"  >
                            <div className="pricing-left">
                                <h2 className="mb-3">
                                    Find the plan
                                    <br className="d-md-block d-none" /> that's right for you
                                </h2>
                                <p>
                                    ShipSagar offers competitive rates and pricing plans to help you
                                    find a plan that fits the needs and budget of your business.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="pricing-box gradient-bg">
                                        <div className="pricing-box-title text-white">Premium</div>
                                        <div className="pricing-box-content text-white mt-3">Pay as you grow</div>
                                        <div className="pricing-box-btn mt-4">
                                            <Link to='/signup' className="btn blue-btn">
                                                Choose Plan
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" >
                                    <div className="pricing-box">
                                        <div className="pricing-box-title">Trial</div>
                                        <div className="pricing-box-content mt-4">Get started now with 250 free shipments.</div>
                                        <div className="pricing-box-btn mt-4">
                                            <Link to='/signup' className="btn red-btn">
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="features-box">
                                <h3 className="main-heading mb-3">Features</h3>
                                <ul className="features-list">
                                    <li>
                                        <i className="fa fa-map-marker" />
                                        <span>Carrier Tracking APIs</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-bar-chart" />
                                        <span>Analytics/Reporting</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-cloud-upload" />
                                        <span>Bulk Upload</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope" />
                                        <span>Email Notifications</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-comment" />                                
                                        <span>SMS Notifications</span>                                 
                                    </li>                                                              
                                    <li>
                                        <i className="fa-brands fa-whatsapp" />                        
                                        <span>Whatsapp Notifications</span>                            
                                    </li>                                                              
                                    <li>
                                        <i className="fa fa-bar-chart" />                              
                                        <span>Pushback/Webhooks</span>                                 
                                    </li>                                                              
                                    <li>
                                        <i className="fa fa-user-secret" />
                                        <span>Branded Tracking Page</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-warning" />
                                        <span>Delivery Issues Alerts</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-mobile-phone" />
                                        <span>Custom Sender Id</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-commenting" />
                                        <span>Order Review/Feedback</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-comments" />
                                        <span>Custom Email Templates</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricing
