import {Link } from 'react-router-dom'
const CommingSoon = () => {
    return (
        <>
            <section className="py-3">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12 mb-5 text-center">
                            <div className="thankyou-img">
                                <img src="images/coming-soon.jpg" className="img-fluid" alt='' />
                            </div>
                            <div className="thankyou-text mt-5">
                                <h3 className="main-heading">We're Coming Soon</h3>
                                <p>We're working hard to give you the best experience!</p>
                                <Link to="/" className="btn blue-btn mt-5">
                                    Back to Home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default CommingSoon