import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import { LoginUrl, SiteKey } from '../../config';
import ThankYou from '../Common Components/ThankYou';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Signup = () => {
    const [Captcha, setCaptchaResponse] = useState('');
    const [isRemember, setIsRemember] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState({ error: false, accepted: false });
    const [isSignUp, setIsSignUp] = useState(true);

    const [formData, setFormData] = useState({
        Name: '',
        EmailID: '',
        PhoneNo: '',
        City: '',
    });
    const navigate = useNavigate();


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleRemember = (event) => {
        const val = event.target.checked;
        setIsRemember(val)
        setIsError(false);
    };
    function onChange(value) {
        setCaptchaResponse(value)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.Name !== '' && formData.EmailID !== '' && formData.PhoneNo !== '' && formData.City !== '' && Captcha !=='') {
            if (isRemember) {
                const data = { ...formData, Captcha }
                setIsLoading(true);
                await fetch('/api/Website/NewRegistration',
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(async (response) => {
                        const res = await response.json();
                        if (res.status === "1") {
                            setIsLoading(false)
                            Swal.fire({
                                text: res.message,
                                icon: 'success'
                            })
                            const expirationTime = new Date();
                            await expirationTime.setTime(expirationTime.getTime() + 1 * 60 * 1000)
                            await cookies.set('process', res.status, { expires: expirationTime });
                            setIsSignUp(false)
                            navigate('/thankyou')
                        } else {
                            Swal.fire({
                                text: res.message,
                                icon: 'error'
                            })
                            setIsLoading(false)
                        }
                    }).catch((err) => {
                        setIsLoading(false)
                        Swal.fire({
                            title: 'internal error',
                            text: err,
                            icon: 'error'
                        })
                    })
                
            } else {
                setIsError({ accepted: true })
            }
        } else {
            setIsError({ error: true });
        }
    };

    useEffect(() => {
        document.title = 'Register | ShipSagar'
    });

    return (
        <>
            {
                isSignUp ? <section className="login-signup-wrapper py-3">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="login-signup-img">
                                    <img src="images/registration.png" className="img-fluid" alt='' />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <form className="login-signup-box" onSubmit={handleSubmit}>
                                    <h3 className="main-heading">Register Now</h3>
                                    <small>
                                        All <span className="text-danger">[*]</span> fields are mandatory.
                                    </small>
                                    <div className="my-3">
                                        <label className="form-label">
                                            Name<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control" name="Name" maxLength='25' value={formData.Name} onChange={handleChange}
                                            style={isError.error ? (formData.Name === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })} />
                                        {isError.error ? (formData.Name === '' ? (<span id='client' className="ml-1 text-danger fs-6">Name is required!</span>) : ('')) : ('')}
                                    </div>
                                    <div className="my-3">
                                        <label className="form-label">
                                            Email<span className="text-danger">*</span>
                                        </label>
                                        <input type="email" className="form-control" maxLength='100' name="EmailID" value={formData.EmailID} onChange={handleChange}
                                            style={isError.error ? (formData.EmailID === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })} />
                                        {isError.error ? (formData.EmailID === '' ? (<span id='client' className="ml-1 text-danger fs-6">Email is required!</span>) : ('')) : ('')}
                                    </div>
                                    <div className="my-3">
                                        <label className="form-label">
                                            Phone No<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control" maxLength='13' name="PhoneNo" value={formData.PhoneNo} onChange={handleChange}
                                            style={isError.error ? (formData.PhoneNo === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })} />
                                        {isError.error ? (formData.PhoneNo === '' ? (<span id='client' className="ml-1 text-danger fs-6">Mobile No. is required!</span>) : ('')) : ('')}
                                    </div>
                                    <div className="my-3">
                                        <label className="form-label">
                                            City<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control" maxLength='100' name="City" value={formData.City} onChange={handleChange}
                                            style={isError.error ? (formData.City === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })} />
                                        {isError.error ? (formData.City === '' ? (<span id='client' className="ml-1 text-danger fs-6">City is required!</span>) : ('')) : ('')}
                                    </div>
                                    <div className="my-3">
                                        <ReCAPTCHA sitekey={SiteKey} onChange={onChange}
                                            style={isError.error ? (formData.ReCAPTCHA === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })} />
                                        {isError.error ? (Captcha === '' ? (<span id='client' className="ml-1 text-danger fs-6">Captcha validation is required!</span>) : ('')) : ('')}
                                    </div>
                                    <div className="form-check my-3">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" checked={isRemember} onChange={handleRemember}
                                            />
                                            By signing up, you agree to our{" "}
                                            <Link to="/terms-and-conditions" >Terms and Conditions.</Link>
                                        </label>
                                    </div>
                                    {isError.accepted ? <span className="ml-1 text-danger fs-6">Please accept terms & conditions!</span> : ''}
                                    <div className="my-3">
                                        {isLoading ? <button type='submit' className="blue-btn btn-disable" disabled={isLoading} > Please wait...</button> :
                                            <button type='submit' className="btn blue-btn " disabled={isLoading} > Create Account</button>}
                                    </div>
                                    <div className="form-group">
                                        <small>
                                            Already have an account?{"  "}
                                            <Link to={LoginUrl} target="_blank">
                                                Login
                                            </Link>
                                        </small>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section> : <ThankYou/>
            }
            
        </>
    )
}
export default Signup



