import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LoginUrl } from '../../config';


const NavMenu = ({ verifyUser }) => {
    const [activeMenu, setactiveMenu]= useState(null)

    const handleMenuClick = (index) => {
        setactiveMenu(activeMenu === index ? null : index)
    }




    const menuItem = [
        {
            path: '/',
            name: 'Home',
        },
        {
            path: '/pricing',
            name: 'Pricing',
        },
        {
            path: '/faq',
            name: "FAQ's",
        },
        {
            path: '/contactus',
            name: 'Contact Us',
        },
    ]
   

    return (
        <section className="menu-bar sticky-top" >
            <nav className="navbar navbar-expand-lg bg-white shadow-sm">
                <div className="container">
                    <NavLink className="navbar-brand" to="index.html">
                        <img src="images/logo.png" className="img-fluid" alt="Navbar Logo" />
                    </NavLink>
                    <button
                        className="navbar-toggler hide-name-menu"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        
                    >
                        {/* <span class="navbar-toggler-icon"></span> */}
                        <i className="fa-solid fa-bars" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {menuItem.map((item, index) => (
                                <li className="nav-item" key={index}>
                                    <NavLink
                                        className="nav-link"
                                        activeclassname={activeMenu === index ? 'active' : ''}
                                        aria-current="page"
                                        onClick={() => handleMenuClick(index)}
                                        to={`${item.path}`}

                                    >
                                        {item.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                        <form className="d-flex" role="search">
                            <NavLink to={LoginUrl } className="btn blue-btn">
                                Log In
                            </NavLink>
                            <NavLink to="/signup" className="btn red-btn"  >
                                Try ShipSagar Free
                            </NavLink>
                        </form>
                    </div>
                </div>
            </nav>
        </section>
    );
}
export default NavMenu


