import React from "react";

const NotFound = () => {
    let URL = window.location.href
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <section className="py-3">
                        <div className="container">
                            <div className="row pt-5">
                                <div className="col-12 mb-5 text-center">
                                    <div className="thankyou-img">
                                        <img src="./images/404.jpg" className="img-fluid" alt='' />
                                    </div>
                                    <div className="thankyou-text mt-5">
                                        <h3 className="main-heading h2">Page not found</h3>
                                        <p className='fs-6'>you are unauthorized for this action <br /> please review the following url and make sure that It is spelled correctly.</p>
                                        <p className="fw-bold fs-6">Requested URL :  <span className='text-danger'>{URL} </span></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};
export default NotFound;