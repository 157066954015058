import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LoginUrl } from '../../config'
const ThankYou = ({ Auth }) => {

    const [auth, setAuth] = useState(true)

    useEffect(() => {
        if (Auth !== undefined) {
            setAuth(Auth);
        }
    },[])

    return (
        <>
            <section className="py-3">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12 mb-5 text-center">
                            {auth ? <div className="thankyou-img">
                                <img
                                    src="images/thank-you.png"
                                    className="img-fluid"
                                    style={{ maxWidth: 100 }}
                                    alt=''
                                />
                            </div> : <div className="oops-img">
                                <img
                                    src="images/oops.png"


                                    alt=''
                                />
                            </div>}
                            <div className="thankyou-text mt-5">
                                <h3 className="main-heading">{auth ? ("Thanks for Registering") : ("Authentication Failed!")}</h3>
                                {auth ? (<><p>You've successfully registered with ShipSagar.</p>
                                    <Link to={LoginUrl} className="btn blue-btn mt-5">
                                        Go to Login
                                    </Link></>) : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default ThankYou