import { useState, useEffect } from "react";
import {  useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Thankyou from '../Common Components/ThankYou'

const EmailVerification = () => {
    const [isVerify, setIsVerify] = useState(false)
    const [auth, setAuth] = useState(true)
    const [disabled, setDisabled] = useState(true)
    const [time, setTime] = useState(60);
    const queryParameters = new URLSearchParams(window.location.search);
    const EmailID = queryParameters.get("EmailID")
    const Token = queryParameters.get("Token")
    const navigate = useNavigate();


    const verifyUser = async () => {
        let data = { EmailID, Token }
        
        if (Token !== null) {
            
            await fetch('/api/website/VerifyUser', {
                method: 'Post',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(async (response) => {
                let res = await response.json()
                
                if (res.status === '1') {
                    await Swal.fire({
                        icon: 'success',
                        text: res.message
                    })
                    setIsVerify(true)
                    setAuth(false)
                    navigate('/thankyou')
                } else{
                    Swal.fire({
                        icon: 'error',
                        text: res.message
                    })
                    setAuth(false)
                    setIsVerify(true) 
                }
            }).catch((err) => {
                Swal.fire({
                    icon: 'error',
                    text: err
                })
            })
        } else {
            setIsVerify(false)
            
        }
    }

    useEffect(() => {
        verifyUser();
    }, [])

    useEffect(() => {
        if (time > 0) {
            const timerID = setTimeout(() => {
                setTime(time - 1);
            }, 1000);
            return () => {
                clearTimeout(timerID);
            };
        } else {
            setDisabled(false);
        }
    }, [time])

    function againsendmail() {
        setDisabled(true)
        setTime(60)
    }

   

    return (
        <>

            <section className="py-5">
                <div className="container">
                    <div className="row pt-5">
                        {isVerify ? <Thankyou Auth={auth } />
                            :
                            <div className="col-12 mb-5 text-center">
                                <div className="thankyou-img">
                                    <img
                                        src="images/email-verification.png"
                                        className="img-fluid"
                                        style={{ maxWidth: 150 }}
                                        alt=''
                                    />
                                </div>
                                <div className="thankyou-text mt-5">
                                    <h3 className="main-heading">Please Verify your E-mail </h3>
                                    <p>
                                        Thank you for creating an account with ShipSagar.
                                        <br />
                                        To complete the registration process, please verify your email address.
                                    </p>
                                    {/*<div className='pt-3'>*/}
                                    {/*    please wait... {time} Sec. */}
                                    {/*    <button className="btn btn-danger mx-1" onClick={againsendmail} id='resend-link' disabled={disabled}>*/}
                                    {/*        Resend Link*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
export default EmailVerification;