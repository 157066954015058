import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BiCheckCircle } from "react-icons/bi"


const Home = () => {

    //const [EmailID, setEmailID] = useState('')
    //const [error, setError] = useState(false)
    const navigate = useNavigate()
    const handleBookDemo = () => {
        navigate('/signup')
    }

    useEffect(() => {
        document.title = 'AI Enabled Parcel Tracking Software for eCommerce '
    }, [])

    return (
        <>
            <section className="baner-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6" >
                            <div className="left-content">
                                <h1>AI Enabled Tracking Solution</h1>
                                <ul>
                                    <li>Send intelligent tracking updates to customers</li>
                                    <li>View tracking across carriers on a single screen</li>
                                    <li>Dedicated tracking URL for each shipment</li>
                                </ul>
                                <Link to='/signup'>
                                    <button className="btn red-btn mb-3">Try ShipSagar Free</button>
                                </Link>
                                <div className="d-flex muted-content" >
                                    <div className="mx-1">
                                        <span className="text-muted mx-1"><BiCheckCircle className="text-muted" style={{ fontSize: "1.2rem" }} /></span>
                                        <span className="text-muted" style={{ fontSize: "0.9rem" }}>No Credit Card required</span>
                                    </div>
                                    <div className="mx-1">
                                        <span className="text-muted mx-1" ><BiCheckCircle style={{ fontSize: "1.2rem" }} /></span>
                                        <span className="text-muted" style={{ fontSize:"0.9rem"} }>No Coding required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="banner-img">
                                <img src="images/banner-img.png" className="img-fluid" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="carrier-partner-wrapper">
                <div className="container" >
                    <h6 className="carrier-partner-title">
                        World's top Multi-carrier Shipping
                    </h6>
                    <div className="carrier-partner">
                        <img src="images/carrier-img/aramax.jpg" className="img-fluid" alt=""  />
                        <img
                            src="images/carrier-img/bluedart.jpg"
                            className="img-fluid"
                            alt=""
                            
                        />
                        <img
                            src="images/carrier-img/delhivery.jpg"
                            className="img-fluid"
                            alt=""
                            
                        />
                        <img src="images/carrier-img/dhl.jpg" className="img-fluid" alt=""  />
                        <img src="images/carrier-img/dtdc.jpg" className="img-fluid" alt=""  />
                        <img src="images/carrier-img/fedex.jpg" className="img-fluid" alt=""  />
                        <img src="images/carrier-img/tnt.jpg" className="img-fluid" alt=""  />
                        <img
                            src="images/carrier-img/atlantic.jpg"
                            className="img-fluid"
                            alt=""
                            
                        />
                    </div>
                </div>
            </section>
            <section className="order-status-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center" >
                            <h2 className="main-heading mb-3 mb-md-5">Track your order status</h2>
                        </div>
                    </div>
                    <div className="row status-box-outer">
                        <div className="col-md-4">
                            <div className="status-box mt-10" >
                                <div className="status-img">
                                    <img src="images/order-received.png" alt='' />
                                </div>
                                <div className="status-title">Order Received</div>
                                <div className="status-text">
                                    Your order has been received by your courier partner
                                </div>
                            </div>
                            <div className="status-box mt-30 ms-auto" >
                                <div className="status-img">
                                    <img src="images/order-picked.png" alt='' />
                                </div>
                                <div className="status-title">Order Picked</div>
                                <div className="status-text">
                                    Your order has been picked up by your courier partner
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" >
                            <div className="order-status-img">
                                <img src="images/order-status1.png" className="img-fluid" alt='' />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="status-box ms-auto mt-10" >
                                <div className="status-img">
                                    <img src="images/delivered.png" alt='' />
                                </div>
                                <div className="status-title">Reached Destination</div>
                                <div className="status-text">
                                    Your order has reached your customer's city
                                </div>
                            </div>
                            <div className="status-box mt-30" >
                                <div className="status-img">
                                    <img src="images/out-for-delivery.png" alt='' />
                                </div>
                                <div className="status-title">Out For Delivery</div>
                                <div className="status-text">
                                    The courier executive is on its way to deliver the order at your
                                    customer's doorstep
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="status-box mx-auto mt-4" >
                                <div className="status-img">
                                    <img src="images/in-transit.png" alt='' />
                                </div>
                                <div className="status-title">Order In Transit</div>
                                <div className="status-text">
                                    Your order is on it's way to your customer's address
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features-wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6" >
                            <div className="features-content">
                                <h3 className="main-heading">Integrated tracking portal</h3>
                                <p>
                                    ShipSagar offers Order Tracking, Notifications, and Reviews
                                    services that cater to worldwide, such as Bombino Express, USPS,
                                    India Post, FedEx, DHL, TNT, UPS, and many more. The platform
                                    facilitates auto tracking of shipments and provides timely updates
                                    to customers regarding their package's transit status, pick-up,
                                    delivery, or any exceptions.
                                </p>
                                <p>
                                    With ShipSagar, you can effortlessly manage your shipments and
                                    enjoy a streamlined shipping experience.
                                </p>
                                <Link to='/signup' className="btn blue-btn">
                                    Start Free Trial
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <div className="features-img">
                                <img src="images/tracking-portal.png" className="img-fluid" alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6" >
                            <div className="features-img">
                                <img src="images/carrier-integration.png" className="img-fluid" alt='' />
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <div className="features-content">
                                <h3 className="main-heading">Enable seamless integration</h3>
                                <p>
                                    With seamless integration and scalable APIs, ShipSagar
                                    effortlessly connects to a wide range of platforms, applications,
                                    and carriers, enabling smooth data flow and automation. Whether
                                    you prefer using APIs or webhooks, ShipSagar seamlessly integrates
                                    into your preferred systems.
                                </p>
                                <Link to='/signup' className="btn blue-btn">
                                    Start Free Trial
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6" >
                            <div className="features-content">
                                <h3 className="main-heading">Comprehensive support</h3>
                                <p>
                                    ShipSagar provides comprehensive support for tracking, returns,
                                    and shipping integrations. Its robust APIs and webhooks are
                                    designed to simplify the engineering process, allowing you to save
                                    valuable time. These integrations cover a vast network of
                                    worldwide carriers, ensuring that you can easily track shipments
                                    across various service providers.
                                </p>
                                <Link to="/signup" className="btn blue-btn">
                                    Start Free Trial
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <div className="features-img">
                                <img src="images/Comprehensive support.png" className="img-fluid" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trusted-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mb-5" >
                            <h3 className="main-heading text-white">
                                Trusted with more over 10,000 brands
                            </h3>
                        </div>
                        <div className="col-md-4 col-6" >
                            <div className="trusted-box text-center">
                                <div className="trusted-img">
                                    <img src="images/courier-integration.png" alt='' />
                                </div>
                                <div className="trusted-value">500+</div>
                                <div className="trusted-caption">Courier Integrated</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6" >
                            <div className="trusted-box text-center">
                                <div className="trusted-img">
                                    <img src="images/free-shipments.png" alt='' />
                                </div>
                                <div className="trusted-value">250</div>
                                <div className="trusted-caption">Free Shipments For Unlimited Time</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12" >
                            <div className="trusted-box text-center">
                                <div className="trusted-img">
                                    <img src="images/shipment-tracking.png" alt='' />
                                </div>
                                <div className="trusted-value">10K+</div>
                                <div className="trusted-caption">Daily Shipments Tracked</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-started-wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-7">
                            <div className="get-started-content">
                                <h2 className="main-heading">Get started in Few Clicks</h2>
                                <p>
                                    Connect with our post-purchase experts to see how ShipSagar can
                                    transform your customer journey.
                                </p>
                                <div className="input-group mb-3">
                                    <div>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={handleBookDemo}>Book a Demo</span>
                                    </div>
                                </div>
                                <Link to="/signup" className="get-started-btn">
                                    Get Started <i className="fa-solid fa-chevron-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-5 offset-lg-2" >
                            <div className="get-started-img">
                                <img src="images/get-started.jpg" alt='' className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home