import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {  logPageView } from './Analytics';


const AnalyticWrapper = ({ path, children }) => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === path) {
            logPageView(location.pathname);
        }
    }, [location,path]);

    return <>{children}</>;
};

export default AnalyticWrapper;