import 'aos/dist/aos.css';
import { Cookies } from 'react-cookie';
import { Route, Routes } from 'react-router-dom';
import { initGA } from "./Analytics";
import AnalyticWrapper from "./AnalyticWrapper";
import AppRoutes from './AppRoutes';
import Footer from './components/Common Components/Footer';
import Header from './components/Common Components/Header';
import NotFound from './components/Common Components/NotFound';
import ThankYou from "./components/Common Components/ThankYou";
import Layout from './components/Layout/Layout';
import NavMenu from './components/Navbar/NavMenu';
import EmailVerification from './components/Signup/EmailVerification';
import './css/style.css';
import Protected from "./Protected";

const cookies = new Cookies();

export const App = () => {

    initGA();

    
    
    return (
        <section className="content-cover">
            <Header />
            <NavMenu />
            <Layout>
                <AnalyticWrapper path="/thankyou">
                    <Routes>
                        
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                        <Route path='/VerifyUser' element={<EmailVerification />} />
                        <Route path='/thankyou' element={<Protected Component={ThankYou} />} />
                        <Route path='/not-found' element={<NotFound />} />
                        <Route path='*' element={<NotFound />} />
                        
                    </Routes>
                </AnalyticWrapper>
            </Layout>
            <Footer />
        </section>
    );
}

