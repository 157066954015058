import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Faq = () => {

    useEffect(() => {
        document.title = 'ShipSagar | FAQ | Help Center'
    }, [])

    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center my-5">
                            <h2 className="main-heading">Frequently Asked Questions</h2>
                        </div>
                        <div className="col-12 mb-5">
                            <div className="accordion" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseOne"
                                        >
                                            What is the validity of my credits?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingOne"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            Credit validity depends on plans. Please{" "}
                                            <Link to='/pricing'> Click Here</Link> for plan details.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseTwo"
                                        >
                                            What are the different shipment notification you will send to
                                            customer once the tracking number is assigned?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingTwo"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            The notification will be sent for following shipment status like
                                            Shipment Booked, In-Transit, Out for Delivery, Delivered. In
                                            additions, we send the notifications on Undelivered Shipment
                                            Status i.e.
                                            <ul className="mb-0 mt-3">
                                                <li>Attempted Delivery</li>
                                                <li>Delay in Delivery</li>
                                                <li>When it reaches the city of delivery</li>
                                                <li>Address Incorrect</li>
                                                <li>Delivery Attempted-Premises Closed</li>
                                                <li>Out of Delivery Area</li>
                                                <li>COD Payment Not Ready</li>
                                                <li>Consignee Unavailable</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseThree"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseThree"
                                        >
                                            Which couriers do you support?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingThree"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            We have integrated 550+ couriers across worldwide.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseFour"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseFour"
                                        >
                                            Is there any trial period?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingFour"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            Yes. There is 7 days free trial period.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseFive"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseFive"
                                        >
                                            Can you integrate additional couriers in the list, which is not
                                            listed in default list ?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingFive"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            Yes. We can integrate new courier as per the request. The
                                            integration process usually takes 10-12 days. You can drop us
                                            the mail at contact@shipsagar.in
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseSix"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseSix"
                                        >
                                            How does your review system work?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseSix"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingSix"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            We have review widget with the delivery emails where customer
                                            can give the rating on scale of 1-10 and then will be take to
                                            link where they can share the overall feedback such as quality
                                            of product, quality of packing, order deliver time etc.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingSeven">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseSeven"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseSeven"
                                        >
                                            Customer has submitted the review but didn't show on website?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseSeven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingSeven"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            Reviews will automatically shown after 3 days. You can also
                                            manage the reviews by archiving unwanted reviews.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Faq