import ContactUs from "./components/HomePage/Contact";
import Faq from "./components/HomePage/Faq";
import Home from "./components/HomePage/Home";
import Pricing from "./components/HomePage/Pricing";
import CommingSoon from './components/Common Components/CommingSoon';
import PrivacyPolicy from "./components/HomePage/PrivacyPolicy";
import Signup from "./components/Signup/Signup";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/pricing',
        element: <Pricing />
    },
    {
        path: '/faq',
        element: <Faq />
    },
    {
        path: '/contactus',
        element: <ContactUs />
    },
    {
        path: '/signup',
        element: <Signup />
    },
    {
        path: '/terms-and-conditions',
        element: <CommingSoon />
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
    },
    //{
    //    path: '/thankyou',
    //    element: <ThankYou />
    //},




];

export default AppRoutes;
