import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha";
import { SiteKey } from '../../config';
const Contact = () => {
    const [formData, setFormData] = useState({
        UserName: '',
        EmailID: '',
        PhoneNo: '',
        Subject: '',
        Description: ''
    });
    const [Captcha, setCaptchaResponse] = useState('')

    const [isError, setIsError] = useState({ error: false, accepted: false })
    const [isRemember, setIsRemember] = useState(false);
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value })); 
    };

    const onChange = (value) => {
        setCaptchaResponse(value)
    }

    const handleRemember = (event) => {
        const val = event.target.checked;
        setIsRemember(val)
        setIsError(false);
    }

    const handleSubmit = async (event) => {
        
        event.preventDefault();
        if (formData.EmailID !== '' && formData.UserName !== '' && formData.PhoneNo !== '' && formData.Subject !== '' && Captcha !== '') {
            if (isRemember) {
                const data = { ...formData, Captcha }
            await fetch(`/api/Website/ContactUs`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(async (response) => {
                const res = await response.json();
                
                if (res.status === 'Success') {
                    Swal.fire(
                        { 
                            text: `${res.message}`,
                            icon: 'success'
                        }
                    )

                } else if (res.status === 'ERROR') {
                    Swal.fire(
                        {
                            text: `${res.message}`,
                            icon: 'error'
                        }
                    )
                } else {
                    Swal.fire(
                        {
                            text: `${res.message}`,
                            icon: 'error'
                        }
                    )
                }

            }).catch((err) => {
                Swal.fire(
                    {
                        title: err,
                        text: 'Something went wrong!, please try after some time.',
                        icon: 'error',

                    }
                )
            })
            } else {
                setIsError({ accepted: true })
            }
        } else {
            setIsError({ error: true });
        }
    }
   
    useEffect(() => {
        document.title = 'Contact Us | ShipSagar'
    })
    return (
        <>
            <section className="login-signup-wrapper py-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 ">
                            <form className="login-signup-box" onSubmit={handleSubmit}>
                                <h3 className="main-heading">Contact Us</h3>
                                <small>
                                    All <span className="text-danger">[*]</span> fields are mandatory.
                                </small>
                                <div className="my-3">
                                    <label className="form-label">
                                        Name<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" maxLength='25'
                                        style={isError.error ? (formData.UserName === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })}
                                        name="UserName" value={formData.UserName} onChange={handleChange} />
                                    {isError.error ? (formData.UserName === '' ? (<span id='client' className="ml-1 text-danger fs-6">Name is required!</span>) : ('')) : ('')}
                                    
                                </div>
                                <div className="my-3">
                                    <label className="form-label">
                                        Email<span className="text-danger">*</span>
                                    </label>
                                    <input type="email" className="form-control" maxLength='100'
                                        style={isError.error ? (formData.EmailID === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })}
                                        name="EmailID" value={formData.EmailID} onChange={handleChange} />
                                    {isError.error ? (formData.EmailID === '' ? (<span id='client' className="ml-1 text-danger fs-6">Email is required!</span>) : ('')) : ('')}
                                </div>
                                <div className="my-3">
                                    <label className="form-label">
                                        Phone No<span className="text-danger">*</span>
                                    </label>
                                    <input type="tel" className="form-control" maxLength='13' style={isError.error ? (formData.PhoneNo === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })}
                                        name="PhoneNo" value={formData.PhoneNo} onChange={handleChange} />
                                    {isError.error ? (formData.PhoneNo === '' ? (<span id='client' className="ml-1 text-danger fs-6">Phone No is required!</span>) : ('')) : ('')}
                                </div>
                                <div className="my-3">
                                    <label className="form-label">
                                        Subject<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" maxLength='250' className="form-control" style={isError.error ? (formData.Subject === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })}
                                        name="Subject" value={formData.Subject} onChange={handleChange} />
                                    {isError.error ? (formData.Subject === '' ? (<span id='client' className="ml-1 text-danger fs-6">Subject is required!</span>) : ('')) : ('')}
                                </div>
                                <div className="my-3">
                                    <label htmlFor="comment">Message</label>
                                    <textarea
                                        className="form-control"
                                        rows={3}
                                        maxLength='500'
                                        id="comment"
                                        name="Description"
                                        value={formData.Description} onChange={handleChange}
                                    />
                                </div>
                                <div className="my-3">
                                    <ReCAPTCHA sitekey={SiteKey} onChange={onChange}
                                        style={isError.error ? (formData.ReCAPTCHA === '' ? { borderColor: 'red' } : { borderColor: '#ced4da' }) : ({ borderColor: '#ced4da' })} />
                                    {isError.error ? (Captcha === '' ? (<span id='client' className="ml-1 text-danger fs-6">Captcha validation is required!</span>) : ('')) : ('')}
                                </div>
                                <div className="form-check mb-3">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" name="remember" onChange={handleRemember} checked={isRemember} /> I agree to the Privacy Policy and consent to sharing my information. To know more refer to our <a href="coming-soon.html"> Privacy Policy.</a>
                                    </label>
                                </div>
                                {isError.accepted ? <span className="ml-1 text-danger fs-6">Please accept terms & conditions!</span> : ''}
                                <div className="my-3">
                                    <button className="btn blue-btn" type='submit'>Send</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5 offset-lg-2">
                            <div className="contact-info">
                                <h3 className="main-heading mb-lg-5 my-3">Contact Information</h3>
                                <div>
                                    <h5 className="main-heading">Make Tracking Easier &amp; Faster</h5>
                                    <p>
                                        Want to make tracking easier for your customers and reduce
                                        unwanted customer calls. Contact Us now and Fill in your details
                                        to experience the new phase of Order Tracking System. Still
                                        confused, how to integrate it in your website. Don't be shy and
                                        make a call, and our representative will get back to you ASAP.
                                    </p>
                                </div>
                                <div>
                                    <h5 className="main-heading">Address</h5>
                                    <p>
                                        66/A, First Floor, Gyandeep Complex,
                                        <br /> Munirka, New Delhi - 110067 INDIA.
                                    </p>
                                </div>
                                <div>
                                    <h5 className="main-heading">Contact Number</h5>
                                    <p>
                                        <a href="tel:+919311746788">+91-9311746788,</a>
                                        <br />
                                        <a href="tel:+919311133772">+91-9311133772</a>
                                    </p>
                                </div>
                                <div>
                                    <h5 className="main-heading">Email ID</h5>
                                    <p>
                                        <a href="mailto:info@shipsagar.com">info@shipsagar.com</a>
                                    </p>
                                </div>
                                <div>
                                    <h5 className="main-heading">FAQ</h5>
                                    <p>
                                        For frequently asked questions
                                        <Link to="/faq">follow this link</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Contact